@tailwind base;
@tailwind components;
@tailwind utilities;

/*@layer utilities {*/
/*    .scrollbar::-webkit-scrollbar {*/
/*        width: 20px;*/
/*        height: 20px;*/
/*    }*/

/*    .scrollbar::-webkit-scrollbar-track {*/
/*        border-radius: 100vh;*/
/*        background: #f7f4ed;*/
/*    }*/

/*    .scrollbar::-webkit-scrollbar-thumb {*/
/*        background: #e0cbcb;*/
/*        border-radius: 100vh;*/
/*        border: 3px solid #f6f7ed;*/
/*    }*/

/*    .scrollbar::-webkit-scrollbar-thumb:hover {*/
/*        background: #c0a0b9;*/
/*    }*/
/*}*/